
import { Vue, Options } from "vue-class-component";

@Options({
  name: "Input",
  props: {
    isPassword: {
      default: false,
      type: Boolean
    },
    placeholder: {
      default: "",
      type: String
    },
    password: {
      default: "",
      type: String
    },
    value: {
      default: "",
      type: String
    },
    errorPassword: {
      default: false,
      type: Boolean
    },
    errorValue: {
      default: false,
      type: Boolean
    }
  }
})
export default class extends Vue {
  public passwordType = "password";
  public iconType = "eye-slash";

  public showPassword() {
    if (this.passwordType === "password" && this.iconType === "eye-slash") {
      this.passwordType = "text";
      this.iconType = "eye";
    } else {
      this.passwordType = "password";
      this.iconType = "eye-slash";
    }
  }
}
