
import { Vue, Options } from "vue-class-component";
import { formatInputNumberOnlyV2, parsingErrorResponse } from "@/app/infrastructures/misc/Utils";
import SelectPhoneCountry from "../../shipment/booking/component/select-phone-country.vue";
import removeNewLineWhitespace from "@/app/infrastructures/misc/common-library/RemoveNewLineWhitespace";
import debounce from "lodash/debounce";
import { LocationController } from "@/app/ui/controllers/LocationController";
import { CustomerManagementController } from "@/app/ui/controllers/CustomerManagementController";
import { OptionsClass } from "@/domain/entities/MainApp";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { Customer } from "@/domain/entities/Customer";
import { ApiStateData } from "@/domain/entities/Api";
import { countries } from "@/app/infrastructures/misc/BulkData";
import { DistrictData } from "@/domain/entities/Location";

@Options({
  components: {
    SelectPhoneCountry,
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name === "login") {
      next();
    }
    if (this.hasChanged && !this.openSuccess) {
      this.onOpenClosePage(to);
      if (this.answer) {
        this.answer = false;
        next();
      } else {
        next(false);
      }
    } else {
      this.name = "";
      next();
    }
  }})
export default class EditManagementCustomer extends Vue {

  countryFiltered = countries;
  apiListData = new ApiStateData();
  customer: Customer  = new Customer();
  id = 0
  firstLanding = true

  mounted(): void {
      this.id = Number(this.$route.query.id || 0)
      this.getCustomerDetail()
  }

  async getCustomerDetail() {
    this.apiListData.loading = true;
    try {
      const resp = await CustomerManagementController.getCustomerDetail(this.id);
      this.name = resp.customerName
      this.extractPhoneDetails(resp.customerPhoneNumber, countries)
      if (resp.customerDistrictId != 0) {
        this.fetchDistrictId(resp.customerDistrictId)
      }
      if (resp.customerZipCode != "") {
        this.zipCode = {id: Number(resp.customerZipCode), name: resp.customerZipCode}
      }
      this.email = resp.customerEmail
      this.address = resp.customerAddress
      this.addressType = resp.customerAddressType
      this.apiListData.errorType = "";
    } catch (error) {
      this.apiListData.errorType = parsingErrorResponse(error).type;
    } finally {
      this.apiListData.loading = false;
      this.firstLanding = false
    }
  }

  goBack() {
    this.$router.back();
  }
  
  get title(): string {
    return (this.$route as any)?.meta?.name;
  }

  get isFormValid() {
    return (true);
  }

 isDisabled = true;

  error = {
    phoneNumber: false,
    phoneNumberCode: false,
  };

  // validation name
  name = "";
  validName = true;

  validateName() {
    this.validName = this.name.length !== 0;
  }

  // phone number
  phoneNumber=""
  phoneNumberCode="+62"
  validPhoneNumber = true;

  filterInput(e: any) {
    e.target.value = formatInputNumberOnlyV2(e.target.value);
    this.validatePhoneNumber();
  }

  validatePhoneNumber() {
    this.validPhoneNumber = this.phoneNumber.length !== 0;
  }

  get errorPhoneNumber() {
    return {
      errorPhone: this.error.phoneNumber,
      errorMandatory: this.errorMandatory[1],
      errorPhoneCode: this.error.phoneNumberCode
    };
  }

  setPhoneNumber(val: string) {
    this.phoneNumber = val
  }

  setPhoneCodeNumber(val: string) {
    this.phoneNumberCode = val
  }

  setErrorPhoneNumberCode(status: boolean) {
    this.error.phoneNumberCode = status;
  }

extractPhoneDetails(number: string, countries: { code: string }[]) {
  const matchingCountry = countries.find(country => number.startsWith(country.code.replace("+", "")));

  const phoneCode: string = matchingCountry ? matchingCountry.code.replace("+", "") : "";
  const phoneNumber: string = number.slice(phoneCode.length); 

    this.phoneNumberCode = "+"+phoneCode
    this.phoneNumber = phoneNumber
}

  // validation email
  email = "";

  address =""
  validAddress = true
  replaceNewLine(value: string) {
    return removeNewLineWhitespace(value, " ");
  }

  validateAddress() {
    this.validAddress = this.address.length !== 0;
  }

  // error indicator mandatory
  currentFieldIndex = 0;
  setFieldIndex(index: number) {
    if (index > this.currentFieldIndex) {
      this.currentFieldIndex = index;
    }
  }

  get errorMandatory() {
    return [
    !this.name && this.currentFieldIndex > 0,
    !this.phoneNumber && this.currentFieldIndex > 1,
    !this.address && this.currentFieldIndex > 2,
    ];
  }

  // city
  destinationCity: any = ""
  destinationCityList: Array<any> = [];
  onFocusdestinationCity() {
    if (!this.destinationCityList.length || !this.destinationCity) {
      LocationController.setLoadingDistrict(true);
      this.fetchdestinationCity("");
    }
  }
  fetchdestinationCity = debounce(async (search: string) => {
    const typeAccuracyAllowed = true;
    const response = await LocationController.getDistrictList({
      search: search,
      status: "",
      page: 1,
      limit: typeAccuracyAllowed ? 50 : 10,
      cache: true
    });
    this.destinationCityList = this.filterDistrict(response);
  }, 250);

  get isLoadingdestinationCity() {
    return LocationController.isLoadingDistrict;
  }

  filterDistrict(response: any) {
    return response
      .filter((key: any) => key.status.toLowerCase() === "active")
      .map((e: any) => ({
        id: e.id,
        name: `${e.name}, ${e.cityName}`,
        code: `${e.originZipCode}`
      }));
  }

  async fetchDistrictId(search: number) {
  if (search) {
    const responseDistrictData = await LocationController.getDetailDistrict({
      id: search,
      notSaveDetail: true
    });
    const disctrictName = responseDistrictData.name + "," + responseDistrictData.cityName
    this.destinationCity = responseDistrictData.name == null ? "" : {id: search, name: disctrictName}
  } else {
    this.destinationCity  =  "" ;
  }
};

  onChangeDestinationCity() {
    if (!this.firstLanding) {
      if (this.destinationCity.code?.includes(",")) {
        const arrayZipcode: string[] = this.destinationCity.code.split(",")
        this.zipCodeList = arrayZipcode.map((e: any) => ({
          code: e,
          name: e
        }))
      } else {
        this.zipCode = this.destinationCity.code ? {code: this.destinationCity.code, name: this.destinationCity.code} : this.zipCode
        this.zipCodeList = [{code: this.destinationCity.code, name: this.destinationCity.code}]
      }
    }
  }

  //zipcode
  zipCode: any = ""
  zipCodeList: Array<any> = [];

  fetchZipCode = debounce(async (search: string) => {
    if (!this.destinationCity.code) {
      const response = await LocationController.getZipCodeList({
        search: search,
        page: 1,
        limit: 10,
      });
      this.zipCodeList = this.filterZipCode(response);
    }
  }, 250);

  filterZipCode(response: any) {
    return response
      .zipCodeData
      .map((e: any) => ({
        code: e.originZipCode,
        name: e.originZipCode,
        id: e.id,
        key: `${e.name}, ${e.cityName}`
      }));
  }

  get isLoadingZipCode() {
    return LocationController.isLoadingZipCode;
  }

  onFocusZipCode() {
    if (!this.zipCodeList.length || !this.zipCode) {
      this.fetchZipCode("");
    }
  }

  onChangeZipCode() {
    if (!this.destinationCity.code && !this.firstLanding) {
      this.destinationCity = this.zipCode.name ? {id: this.zipCode.id, name: this.zipCode.key} : ""
    }
  }

  //address type

  addressType = "";
  addressTypeSelect = false;
  addressTypeData = [
    { name: "Pilih tipe alamat ", value: "" },
    { name: "Rumah", value: "rumah" },
    { name: "Kantor", value: "kantor" }
  ];

  onOpenAddressType() {
    this.addressTypeSelect = true;
  }

  onCloseAddressType() {
    this.addressTypeSelect = false;
  }

  onSelectAddressType(name: string, value: string) {
    this.addressType = value;
  }

  get isSubmitLoading(): boolean {
    return CustomerManagementController.isSubmitLoading
  }

  get isErrorForm() {
    return (
      this.name == "" ||
      this.phoneNumber == "" ||
      this.address == ""
    );
  }

  get openModalConfirmation() {
    return CustomerManagementController.openModalConfirmation;
  }

  openConfirmationModal() {
    this.setOpenModalConfirmation(true)
  }

  setOpenModalConfirmation(open: boolean) {
    CustomerManagementController.setOpenModalConfirmation(open)
  }

  handlerSubmit() {
    this.setOpenModalConfirmation(false)
    this._onEdit()
  }

  onCloseModal() {
    this.setOpenModalConfirmation(false)
  }

  openSnackbar(name: string) {
    MainAppController.setSnackbarMessage(`Perubahaan data pelanggan "${name}" berhasil!`);
    MainAppController.setSnackbar(true);
  }

  _onEdit() {
    CustomerManagementController._onEditCustomer({
      id: this.id,
      name: this.name,
      phone: `${this.phoneNumberCode.substring(1)}${
            this.phoneNumber
          }`,
      email: this.email,
      address: this.address,
      districtId: Number(this.destinationCity.id),
      zipCode: this.zipCode.name,
      addressType: this.addressType.toLocaleLowerCase()
    }).then(()=> {
      const customerName = this.name
      this.$router.push(`/management-customer`).then(() => {
        this.openSnackbar(customerName)
      })
    })
  }

get openModalDelete() {
  return CustomerManagementController.openModalDelete;
}

openDeleteModal() {
  this.setOpenModalDelete(true)
}

setOpenModalDelete(open: boolean) {
  CustomerManagementController.setOpenModalDelete(open)
}

handlerDelete() {
  this.setOpenModalDelete(false)
  this._onDelete()
}

onCloseModalDelete() {
  this.setOpenModalDelete(false)
}

  async _onDelete() {
    MainAppController.showLoading();
    try {
      await CustomerManagementController.deleteCustomer(this.id);
      const customerName = this.name
      this.$router.replace(`/management-customer`).then(() => {
        MainAppController.setSnackbarMessage(`Penghapusan data pelanggan "${customerName}" berhasil!`);
        MainAppController.setSnackbarPositivity(false);
        MainAppController.setSnackbar(true);
      })
    } catch (error) {
      MainAppController.showErrorMessage(
        parsingErrorResponse(error, "Gagal Menghapus Customer", () => {
          MainAppController.closeErrorMessage();
          this.openDeleteModal();
        })
      );
    } finally {
      MainAppController.closeLoading();
    }
  }
};
