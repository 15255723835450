
import { Options, Vue } from "vue-class-component";
import Input from "@/app/ui/components/input/index.vue";
import { UserController } from "@/app/ui/controllers/UserController";
import { formatDate } from "@/app/infrastructures/misc/Utils";

@Options({
  components: {
    Input
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name === "login") {
      next();
    }
    if (!this.openSuccess) {
      this.onOpenClosePage(to);
      if (this.answer) {
        this.answer = false;
        next();
      } else {
        next(false);
      }
    } else {
      UserController.setOpenModalSuccess(false);
      next();
    }
  }
})
export default class Edit extends Vue {
  async created() {
    this.getDetailUserData(this.$route.params.id);
  }

  goBack() {
    this.$router.back();
  }

  id() {
    return this.$route.params.id;
  }

  item = {
    status: "Active"
  };

  isDisabled = true;

  // Detail data
  getDetailUserData(id: any) {
    return UserController.getDetailUser({ id });
  }

  fetchUserData() {
    this.getDetailUserData(this.$route.params.id);
  }

  createdAt = "";
  createdBy = "";
  updateAt = "";
  updateBy = "";

  get dataDetail() {
    const detail = UserController.userDetail;
    this.createdAt = formatDate(detail.created_at);
    this.createdBy = detail.created_by;
    this.updateAt =
      detail.updated_at === "0001-01-01T00:00:00Z"
        ? formatDate(detail.created_at)
        : formatDate(detail.updated_at);
    this.updateBy = detail.updated_by;
    return detail;
  }

  // Toggle Route
  openRoute = false;
  onOpenRoute() {
    this.openRoute = true;
  }
  onCloseRoute() {
    this.openRoute = false;
  }
  isRoute() {
    return UserController.isRoute;
  }
  changeRoute() {
    UserController.setRoute(!this.isRoute());
    this.openRoute = false;
  }

  // Modal Success
  messageSuccess = `User dengan nama ${this.dataDetail.username} berhasil diubah`;
  get openSuccess() {
    return UserController.openModalSuccess;
  }
  onCloseSuccess() {
    this.$router.push(`/user-management/${this.$route.params.id}`);
  }

  // Save data
  openSave() {
    return UserController.openModal;
  }
  onOpenSave() {
    this.handleErrorClose();
    UserController.setOpenModal(true);
  }
  onCloseSave() {
    UserController.setOpenModal(false);
  }

  _onEdit() {
    this.onCloseSave();
    this.handleErrorClose();
    UserController._onEditUser({
      id: Number(this.id()),
      isActive: this.isRoute() ? "active" : "inactive",
      email: this.dataDetail.email,
      type: "User"
    });
  }

  // Close when changed
  isEditTerminated = false;
  newPath = "";
  answer = false;

  onOpenClosePage(to: any) {
    this.isEditTerminated = true;
    this.newPath = to.path;
  }

  handlerClose() {
    this.isEditTerminated = false;
    this.answer = true;
    this.$router.push(this.newPath);
  }

  handlerCancelClose() {
    this.answer = false;
    this.isEditTerminated = false;
  }

  //Loading
  get isLoading() {
    return UserController.isLoadingDetail;
  }

  //Error
  get isError() {
    return UserController.isError;
  }

  //Error Cause
  get errorCause() {
    return UserController.errorCause;
  }

  handleErrorClose() {
    UserController.handleError();
  }
}
