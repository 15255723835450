
import { CustomerManagementController } from "@/app/ui/controllers/CustomerManagementController";
import { Vue } from "vue-class-component";
import { FlagsPermissionManagementCustomer } from "@/feature-flags/flags-permission-management-customer";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { parsingErrorResponse } from "@/app/infrastructures/misc/Utils";
import { Customer } from "@/domain/entities/Customer";

export default class DetailCMSBanner extends Vue {
  dataDetail = new Customer()
  name = "";

  get isEditAble() {
    return FlagsPermissionManagementCustomer.management_customer_edit.isEnabled();
  }

  goBack() {
    this.$router.push(`/management-customer`);
  }

  mounted() {
    this.fetchDetail();
  }

  get id(): number {
    return Number(this.$route.params.id);
  }

  async fetchDetail() {
    this.dataDetail = await CustomerManagementController.getCustomerDetail(this.id);
    this.name = this.dataDetail.customerName;
  }

  goToEdit() {
    this.$router.push(`/management-customer/edit?id=${this.id}`);
  }
  
  get openModalDelete() {
    return CustomerManagementController.openModalDelete;
  }

  openDeleteModal() {
    this.setOpenModalDelete(true)
  }

  setOpenModalDelete(open: boolean) {
    CustomerManagementController.setOpenModalDelete(open)
  }

  handlerDelete() {
    this.setOpenModalDelete(false)
    this.onDelete()
  }

  onCloseModalDelete() {
    this.setOpenModalDelete(false)
  }

  async onDelete() {
    MainAppController.showLoading();
    try {
      await CustomerManagementController.deleteCustomer(this.id);
    } catch (error) {
      MainAppController.showErrorMessage(
        parsingErrorResponse(error, "Gagal Menghapus Customer", () => {
          MainAppController.closeErrorMessage();
          this.openDeleteModal();
        })
      );
    } finally {
      MainAppController.closeLoading();
      const customerName = this.name
      this.$router.push(`/management-customer`).then(() => {
        MainAppController.setSnackbarMessage(`Penghapusan data pelanggan "${customerName}" berhasil!`);
        MainAppController.setSnackbarPositivity(false);
        MainAppController.setSnackbar(true);
      })
    }
  }
}
