import { AccountController } from "@/app/ui/controllers/AccountController";

const INVALID_PHONE_NUMBER_MESSAGE = "Nomor telepon tidak valid, mohon ulangi kembali";
const VALID_PHONE_NUMBER_MSISDN = "Nomor telepon valid dengan tipe MSISDN";
const VALID_PHONE_NUMBER_PSTN = "Nomor telepon valid dengan tipe PSTN";
export class ErrorPhoneNumber {
  error = false;
  success = false;
  message = "";
  constructor(fields?: Partial<ErrorPhoneNumber>) {
    Object.assign(this, fields);
  }
}
export const ValidateRepeatingDigit = (value: string, digitRepeat = 7) => {
  if (!value) return false;
  const regexRepeatingDigit = new RegExp(`(\\d)(\\1){${digitRepeat - 1}}`);
  return !regexRepeatingDigit.test(value);
};
export const ValidateMsisdn = (phone: string, errorMessage = "") => {
  const regexMsisdn = /^(\+62|62)?[\s-]?0?8[1-9]\d[\s-]?\d{4}[\s-]?\d{2,5}$/;
  if (regexMsisdn.test(phone) && ValidateRepeatingDigit(phone)) {
    // valid with success message
    return new ErrorPhoneNumber({
      success: true,
      message: VALID_PHONE_NUMBER_MSISDN
    });
  } else {
    // invalid with error message
    return new ErrorPhoneNumber({
      error: true,
      message: errorMessage || INVALID_PHONE_NUMBER_MESSAGE
    });
  }
};
export const ValidatePstn = (phone: string) => {
  const regexPstn = /^(\+62|62)?[\s-]?0?([2-7]|9)\d(\d)?[\s-]?[2-9](\d){6,7}$/;

  if (regexPstn.test(phone) && ValidateRepeatingDigit(phone)) {
    // valid with success message
    return new ErrorPhoneNumber({
      success: true,
      message: VALID_PHONE_NUMBER_PSTN
    });
  } else {
    // invalid with error message
    return new ErrorPhoneNumber({
      error: true,
      message: INVALID_PHONE_NUMBER_MESSAGE
    });
  }
};
export const ValidateInternationalPhone = (phone: string) => {
  const regexPhoneInternational = /^\+?(?:\d ?){6,14}\d$/;
  if (regexPhoneInternational.test(phone)) {
    // valid without success message
    return new ErrorPhoneNumber({
      success: true
    });
  } else {
    // invalid with error message
    return new ErrorPhoneNumber({
      error: true,
      message: INVALID_PHONE_NUMBER_MESSAGE
    });
  }
};
export const ValidateInputPhoneNumber = (phone: string) => {
  if (!phone) {
    return new ErrorPhoneNumber();
  }
  if (phone.startsWith("62") || phone.startsWith("+62")) {
    if (phone.match(/^(\+62|62|0)8/)) {
      return ValidateMsisdn(phone);
    }
    if (phone.match(/^(\+62|62|0)([2-7]|9)/)) {
      return ValidatePstn(phone);
    }
    return new ErrorPhoneNumber({
      error: true,
      message: INVALID_PHONE_NUMBER_MESSAGE
    });
  }
  return ValidateInternationalPhone(phone);
};
