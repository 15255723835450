import { CustomerManagementPresenter } from "@/app/ui/presenters/CustomerManagementPresenter";
import { CustomerMapper } from "@/data/persistences/mappers/CustomerMapper";
import { container } from "tsyringe";
import { CustomerManagementEndpoints } from "../../endpoints/horde/CustomerManagementEndpoints";
import { CustomerManagementApiRepository } from "../../repositories/api/CustomerManagementApiRepository";
import ApiService from "../../services/ApiService";

export class CustomerManagementComponent {
  public static init() {
    container.register<CustomerManagementApiRepository>(CustomerManagementApiRepository, {
      useFactory: d => {
        return new CustomerManagementApiRepository(
          d.resolve(ApiService),
          d.resolve(CustomerMapper),
          d.resolve(CustomerManagementEndpoints)
        );
      }
    });
    container.register<CustomerMapper>(CustomerMapper, {
      useClass: CustomerMapper
    });
    container.register<CustomerManagementPresenter>(CustomerManagementPresenter, {
      useFactory: d => {
        return new CustomerManagementPresenter(d.resolve(CustomerManagementApiRepository));
      }
    });
  }
}
