
import { Options, Vue } from "vue-class-component";
import Input from "@/app/ui/components/input/index.vue";
import { UserController } from "@/app/ui/controllers/UserController";
import router from "@/app/ui/router";
import {
  convertPhoneNumber,
  formatInputNumberOnlyV2
} from "@/app/infrastructures/misc/Utils";
import debounce from "lodash/debounce";
import { AccountController } from "@/app/ui/controllers/AccountController";

@Options({
  components: {
    Input
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name === "login") {
      next();
    }
    if (this.hasChanged && !this.openSuccess) {
      this.onOpenClosePage(to);
      if (this.answer) {
        this.answer = false;
        next();
      } else {
        next(false);
      }
    } else {
      UserController.setOpenModalSuccess(false);
      next();
    }
  }
})
export default class Add extends Vue {
  goBack() {
    this.$router.back();
  }

  item = {
    status: "Active"
  };

  isDisabled = true;

  // Modal Success
  messageSuccess = "";
  get openSuccess() {
    return UserController.openModalSuccess;
  }
  onCloseSuccess() {
    router.push(`/user-management`);
  }

  // Save data
  get openSave() {
    return UserController.openModal;
  }
  onOpenSave() {
    UserController.setOpenModal(true);
  }
  onCloseSave() {
    UserController.setOpenModal(false);
  }

  _onCreate() {
    this.onCloseSave();
    UserController._onAddUser({
      username: this.username,
      email: this.email,
      phoneNumber: convertPhoneNumber(this.phoneNumber),
      accountType: this.typeValue.split("_")[0],
      roleId: this.roleValue,
      referenceId: this.referenceName.value
    });
  }

  // Close when changed
  isEditTerminated = false;
  newPath = "";
  answer = false;

  get hasChanged() {
    return (
      this.username !== "" ||
      this.email !== "" ||
      this.phoneNumber !== "" ||
      this.typeName !== "" ||
      this.roleName !== "" ||
      this.referenceName !== ""
    );
  }

  onOpenClosePage(to: any) {
    this.isEditTerminated = true;
    this.newPath = to.path;
  }

  handlerClose() {
    this.isEditTerminated = false;
    this.answer = true;
    this.$router.push(this.newPath);
  }

  handlerCancelClose() {
    this.answer = false;
    this.isEditTerminated = false;
  }

  // Tipe Akun
  typeSelect = false;
  onOpenTypeSelect() {
    this.typeSelect = true;
  }
  onCloseTypeSelect() {
    this.typeSelect = false;
  }
  typeName = "";
  typeValue = "";
  onSelectType(name: string, value: string) {
    this.onCloseTypeSelect();
    UserController.selectTypeActionAdd(value);
    this.referenceName = "";
    this.typeName = name;
    this.typeValue = value;
  }

  get roleAccount() {
    return (
      AccountController.accountData.account_type_detail.type ||
      AccountController.accountData.account_type
    );
  }
  get isClientParent() {
    return AccountController.accountData.account_type_detail.parentId === 0;
  }
  get typeData() {
    switch (this.roleAccount) {
      case "internal":
        return [
          { name: "Pilih tipe akun", value: "" },
          { name: "Internal", value: "internal" },
          { name: "Client Parent", value: "client_parent" },
          { name: "Client Branch", value: "client_branch" },
          { name: "Vendor", value: "vendor" },
          { name: "Console", value: "console" },
          { name: "Sub-Console", value: "sub-console" },
          { name: "POS", value: "pos" },
          { name: "Customer Service", value: "customer-service" }
        ];
      case "console":
        return [
          { name: "Pilih tipe akun", value: "" },
          { name: "Console", value: "console" }
        ];
      case "sub-console":
        return [
          { name: "Pilih tipe akun", value: "" },
          { name: "Sub-Console", value: "sub-console" }
        ];
      case "pos":
        return [
          { name: "Pilih tipe akun", value: "" },
          { name: "POS", value: "pos" }
        ];
      case "client":
        if (this.isClientParent) {
          return [
            { name: "Pilih tipe akun", value: "" },
            { name: "Client Parent", value: "client_parent" },
            { name: "Client Branch", value: "client_branch" }
          ];
        }
        return [
          { name: "Pilih tipe akun", value: "" },
          { name: "Client Branch", value: "client_branch" }
        ];
      default:
        return [{ name: "Pilih tipe akun", value: "" }];
    }
  }

  // Role
  roleSelect = false;
  onOpenRoleSelect() {
    this.roleSelect = true;
  }
  onCloseRoleSelect() {
    this.roleSelect = false;
  }
  roleName = "";
  roleValue = 0;
  onSelectRole(name: string, value: number) {
    this.onCloseRoleSelect();
    this.roleName = name;
    this.roleValue = value;
  }

  get roleData() {
    return [{ name: "Pilih role", value: "" }, ...UserController.roleDataList];
  }

  // Reference
  loadingReference = false;
  referenceName: any = "";
  focusListReference() {
    this.loadingReference = true;
    this.fetchListReference("");
  }
  fetchListReference = debounce(async (search: string) => {
    this.loadingReference = true;
    await UserController.getReference(search);
    this.loadingReference = false;
  }, 250);
  get referenceDataList() {
    return this.typeValue.includes("client")
      ? UserController.referenceDataList.filter((key: any) => {
          return key.isStatusClientActive;
        })
      : UserController.referenceDataList;
  }
  onSelectReference(value: any) {
    if (value) {
      this.referenceName = value;
    }
  }

  // validation username
  username = "";
  validUsername = true;

  validateUsername() {
    this.validUsername = /^\S*$/.test(this.username);
  }

  // validation email
  email = "";
  validEmail = true;

  validateEmail() {
    this.validEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.email);
  }

  // phone number
  phoneNumber = "";

  filterInput(e: any) {
    e.target.value = formatInputNumberOnlyV2(e.target.value);
  }

  get isFormValid() {
    return (
      this.username !== "" &&
      this.email !== "" &&
      this.phoneNumber !== "" &&
      this.validUsername &&
      this.validEmail &&
      this.typeValue !== "" &&
      this.roleValue !== 0 &&
      this.isValidReference
    );
  }
  get isValidReference() {
    if (this.typeName.match(/^Internal$|^Customer Service$/)) {
      return true;
    }
    return this.referenceName !== "";
  }
}
