import { CustomerManagementComponent } from "@/app/infrastructures/dependencies/modules/CustomerManagementComponent";
import {
    Customer,
    CustomerListEntities,
    RequestCustomerList
} from "@/domain/entities/Customer";
import store from "@/store";
import { container } from "tsyringe";
import { Action, Module, Mutation, VuexModule, getModule } from "vuex-module-decorators";
import { CustomerManagementPresenter } from "../presenters/CustomerManagementPresenter";
import parsingErrorResponse from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { AddCustomerManagementApiRequest , EditCustomerManagementApiRequest, EditCustomerOnBookingManagementApiRequest} from "@/data/payload/api/CustomerManagementApiRequest";

interface CustomerManagementState {
    customerList: CustomerListEntities;
    customerData: Customer;
    isLoading: boolean;
}

CustomerManagementComponent.init();

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "cutomer-management"
})
class CustomerManagementStore extends VuexModule implements CustomerManagementState {
  public customerList: CustomerListEntities = new CustomerListEntities();
  public customerData: Customer = new Customer();
  public isLoading = false;
  public openModal = false;
  public openModalSuccess = false;
  public openModalError = false;
  public openModalConfirmation = false;
  public openModalDelete = false;
  public isSubmitLoading = false

  @Action
  public getCustomerManagementList(params: RequestCustomerList) {
    const presenter = container.resolve(CustomerManagementPresenter);
    return presenter.getCustomerManagementList(params);
  }

  @Action
  public _onAddCustomer(params: {
    name: string;
    phone: string;
    email: string;
    address: string;
    districtId: number;
    zipCode: string;
    addressType: string;
  }) {
    this.setIsLoadingSubmit(true)
    this.setOpenModal(false);
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(CustomerManagementPresenter);
    return new Promise((resolve, reject) => {
      presenter
      .addCustomer(
        new AddCustomerManagementApiRequest(
          params.name,
          params.phone,
          params.address,
          params.email,
          params.districtId,
          params.zipCode,
          params.addressType
        )
      )
      .then(() => {
        this.setOpenModalSuccess(true);
        this.setOpenModalError(false);
        resolve(true)
      })
      .catch(error => {
        if (error.response.data.message.id === "Customer sudah terdaftar") {
          this.setOpenModalError(true);
        } else {
          MainAppController.showErrorMessage(
            parsingErrorResponse(error, "Pembuatan Gagal !", () =>
              this._onAddCustomer(params)
            )
          );
        }
        this.setOpenModalSuccess(false);
        reject(error)
      })
      .finally(() => {
        this.setOpenModal(false);
        this.setIsLoadingSubmit(false)
        MainAppController.closeLoading();
      });
    })
  }

  @Action
  public getCustomerDetail(id: number) {
    const presenter = container.resolve(CustomerManagementPresenter);
    return presenter.getDetailCustomer(id);
  }

  @Action
  public _onEditCustomer(params: {
    id: number;
    name: string;
    phone: string;
    email: string;
    address: string;
    districtId: number;
    zipCode: string;
    addressType: string;
  }) {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(CustomerManagementPresenter);
    return new Promise((resolve, reject) => {
      presenter
      .editCustomer(
        params.id,
        new EditCustomerManagementApiRequest(
          params.name,
          params.phone,
          params.address,
          params.email,
          params.districtId,
          params.zipCode,
          params.addressType
        )
      )
      .then(() => {
        resolve(true)
      })
      .catch(error => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(error, "Pembuatan Gagal !", () =>
            this._onAddCustomer(params)
          )
        );
        reject(error)
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
    })
  }

  @Action
  public editCustomerOnBooking(params: {
    id: number;
    name: string;
    phone: string;
    address: string;
    districtId: number;
    zipCode: string;
    addressType: string;
  }) {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(CustomerManagementPresenter);
    return new Promise((resolve, reject) => {
      presenter
      .editCustomer(
        params.id,
        new EditCustomerOnBookingManagementApiRequest(
          params.name,
          params.phone,
          params.address,
          params.districtId,
          params.zipCode,
          params.addressType
        )
      )
      .then(() => {
        resolve(true)
      })
      .catch(error => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(error, "Pembuatan Gagal !", () =>
            this.editCustomerOnBooking(params)
          )
        );
        reject(error)
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
    })
  }

  @Action
  public deleteCustomer(id: number) {
    const presenter = container.resolve(CustomerManagementPresenter);
    return presenter.deleteCustomer(id);
  }

  @Mutation
  public setOpenModal(value: boolean) {
    this.openModal = value;
  }

  @Mutation
  public setOpenModalSuccess(value: boolean) {
    this.openModalSuccess = value;
  }

  @Mutation
  public setOpenModalError(value: boolean) {
    this.openModalError = value;
  }

  @Mutation
  public setOpenModalConfirmation(value: boolean) {
    this.openModalConfirmation = value;
  }

  @Mutation
  public setOpenModalDelete(value: boolean) {
    this.openModalDelete = value;
  }

  @Mutation
  public setIsLoadingSubmit(value: boolean) {
    this.isSubmitLoading = value;
  }
}

export const CustomerManagementController = getModule(CustomerManagementStore);