
import { Options, Vue, prop } from "vue-class-component";
import debounce from "lodash/debounce";
import { UserController } from "@/app/ui/controllers/UserController";
import { AccountController } from "@/app/ui/controllers/AccountController";

class Prop {
  isDisabled = prop<boolean>({
    type: Boolean,
    default: false
  });
}

@Options({
  emits: ["opened"],
  components: {},
  directives: {
    "click-outside": {
      beforeMount: function(el, binding) {
        const ourClickEventHandler = (event: any) => {
          if (!el.contains(event.target) && el !== event.target) {
            binding.value(event);
          }
        };
        el.__vueClickEventHandler__ = ourClickEventHandler;
        document.addEventListener("click", ourClickEventHandler);
      },
      unmounted: function(el) {
        document.removeEventListener("click", el.__vueClickEventHandler__);
      }
    }
  }
})
export default class AdvanceFilterUserManagement extends Vue.with(Prop) {
  isChevron = false;
  openFilter = false;

  get roleAccount() {
    return (
      AccountController.accountData.account_type_detail.type ||
      AccountController.accountData.account_type
    );
  }
  get isClientParent() {
    return AccountController.accountData.account_type_detail.parentId === 0;
  }

  resetFilter() {
    this.typeValue = "";
    this.referenceModelValue = "";
    this.statusName = "";
    UserController.resetFilter();
  }

  get sumFilter() {
    return (
      (this.typeValue !== "" ? 1 : 0) +
      (this.referenceModelValue !== "" ? 1 : 0) +
      (this.statusName !== "" ? 1 : 0)
    );
  }

  // filter type akun
  typeSelect = false;
  onOpenTypeSelect() {
    this.typeSelect = true;
  }
  onCloseTypeSelect() {
    this.typeSelect = false;
  }
  typeName = "";
  typeValue = "";
  onSelectType(name: string, value: string) {
    this.onCloseTypeSelect();
    if (this.typeValue !== value) {
      this.referenceModelValue = "";
    }
    this.typeValue = value;
    this.typeName = name;
    UserController.selectTypeAction(value);
  }
  get typeData() {
    switch (this.roleAccount) {
      case "internal":
        return [
          { name: "Pilih tipe akun", value: "" },
          { name: "Internal", value: "internal" },
          { name: "Client Parent", value: "client_parent" },
          { name: "Client Branch", value: "client_branch" },
          { name: "Vendor", value: "vendor" },
          { name: "Console", value: "console" },
          { name: "Sub-Console", value: "sub-console" },
          { name: "POS", value: "pos" },
          { name: "Customer Service", value: "customer-service" }
        ];
      case "console":
        return [
          { name: "Pilih tipe akun", value: "" },
          { name: "Console", value: "console" }
        ];
      case "sub-console":
        return [
          { name: "Pilih tipe akun", value: "" },
          { name: "Sub-Console", value: "sub-console" }
        ];
      case "pos":
        return [
          { name: "Pilih tipe akun", value: "" },
          { name: "POS", value: "pos" }
        ];
      case "client":
        if (this.isClientParent) {
          return [
            { name: "Pilih tipe akun", value: "" },
            { name: "Client Parent", value: "client_parent" },
            { name: "Client Branch", value: "client_branch" }
          ];
        }
        return [
          { name: "Pilih tipe akun", value: "" },
          { name: "Client Branch", value: "client_branch" }
        ];
      default:
        return [{ name: "Pilih tipe akun", value: "" }];
    }
  }

  // filter status
  statusSelect = false;
  statusData = [
    { name: "Pilih status", value: "" },
    { name: "Active", value: "active" },
    { name: "Inactive", value: "inactive" }
  ];
  onOpenStatusSelect() {
    this.statusSelect = true;
  }
  onCloseStatusSelect() {
    this.statusSelect = false;
  }
  statusName = "";
  get status() {
    return UserController.status;
  }
  onSelectStatus(name: string, value: string) {
    this.statusName = value;
    UserController.selectStatusAction(value);
    this.onCloseStatusSelect();
  }

  // filter reference
  loadingReference = false;
  referenceModelValue: any = "";
  focusListReference() {
    this.loadingReference = true;
    this.fetchListReference("");
  }
  fetchListReference = debounce(async (search: string) => {
    this.loadingReference = true;
    await UserController.getReference(search);
    this.loadingReference = false;
  }, 250);
  get referenceDataList() {
    return UserController.referenceDataList;
  }
  onSelectReference(value: any) {
    if (value) {
      this.referenceModelValue = value;
      UserController.selectReferenceAction(value.value);
      return;
    }
    this.referenceModelValue = "";
  }
}
