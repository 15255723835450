
import { Options, prop, Vue } from "vue-class-component";
import RadioButton from "primevue/radiobutton";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { ModalMessageEntities, OptionsClass } from "@/domain/entities/MainApp";
import { UserController } from "@/app/ui/controllers/UserController";
import Input from "@/app/ui/components/input/index.vue";
import { RoleEntities } from "@/domain/entities/User";
import { EditRoleApiRequest } from "@/data/payload/api/UserApiRequest";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { parsingErrorResponse } from "@/app/infrastructures/misc/Utils";

class Props {
  modelValue = prop<boolean>({
    type: Boolean,
    default: false
  });
  type = prop<string>({
    type: String,
    default: ""
  });
}

@Options({
  emits: ["update:modelValue", "success"],
  components: {
    RadioButton,
    Input
  }
})
export default class ModalEditDetail extends Vue.with(Props) {
  get title() {
    switch (this.type) {
      case "status":
        return "Ubah Status Akun";
      case "email":
        return "Ubah Email";
      case "role":
        return "Ubah Role";
      default:
        return "Ubah";
    }
  }
  toggleModalEditDetail(bool: boolean) {
    this.$emit("update:modelValue", bool);
  }
  openSnackbar(message: string) {
    MainAppController.setSnackbarMessage(message);
    MainAppController.setSnackbar(true);
  }
  get isFormValid() {
    switch (this.type) {
      case "status":
        return true;
      case "role":
        return !!this.roleDefault.value;
      case "email":
        return !this.errorFormEmail;
      default:
        return false;
    }
  }
  submitConfirmation() {
    switch (this.type) {
      case "status":
        this.confirmationEditStatus();
        break;
      case "email":
        this.confirmationEdit();
        break;
      case "role":
        this.confirmationEdit();
        break;
      default:
        break;
    }
  }

  // confirmation change status
  statusAccount = UserController.userDetail.status.toLowerCase();
  confirmationEditStatus() {
    this.toggleModalEditDetail(false);
    MainAppController.showMessageModal(
      new ModalMessageEntities({
        title: `Konfirmasi ${
          this.statusAccount === "active" ? "Pengaktifan" : "Penonaktifan"
        }`,
        message:
          "Akun yang tidak aktif tidak dapat melakukan aktivitas apapun sampai akun diaktifkan kembali.",
        textCancel: "Kembali",
        textSuccess: `${
          this.statusAccount === "active" ? "Aktifkan" : "Nonaktifkan"
        }`,
        onSubmit: () => this.submitEditStatus(),
        onClose: () => {
          this.toggleModalEditDetail(true);
          MainAppController.closeMessageModal();
        },
        image: "are-you-sure"
      })
    );
  }
  async submitEditStatus() {
    MainAppController.closeMessageModal();
    const resp = await UserController._onEditUser({
      id: Number(UserController.userDetail.id),
      isActive: this.statusAccount,
      email: "",
      type: "Status Akun"
    });
    if (resp) {
      this.openSnackbar(
        `${
          this.statusAccount === "active" ? "Pengaktifan" : "Penonaktifan"
        } status berhasil !`
      );
      this.$emit("success");
    }
  }

  // confirmation change email
  // new email
  newEmail = "";
  get errorFormEmail() {
    return !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.newEmail);
  }
  // old email
  get oldEmail() {
    return UserController.userDetail.email;
  }
  confirmationEdit() {
    this.toggleModalEditDetail(false);
    MainAppController.showMessageModal(
      new ModalMessageEntities({
        title: "Konfirmasi Perubahan",
        message: `Anda yakin akan menyimpan perubahan detail Akun dengan username “${UserController.userDetail.username}” ?`,
        textCancel: "Kembali",
        textSuccess: "Simpan",
        onSubmit:
          this.type === "email" ? this.submitEditEmail : this.submitEditRole,
        onClose: () => {
          this.toggleModalEditDetail(true);
          MainAppController.closeMessageModal();
        },
        image: "are-you-sure"
      })
    );
  }
  async submitEditEmail() {
    MainAppController.closeMessageModal();
    const resp = await UserController._onEditUser({
      id: Number(UserController.userDetail.id),
      isActive: this.statusAccount,
      email: this.newEmail,
      type: "Email"
    });
    if (resp) {
      this.openSnackbar(`Perubahan email berhasil !`);
      this.$emit("success");
    }
  }

  // Role
  get roleDefault(): OptionsClass {
    return new OptionsClass(UserController.roleDefault);
  }
  setRoleDefault(data: OptionsClass) {
    UserController.setRoleDefault(data);
  }
  get roleData() {
    return UserController.roleData.map(
      (item: RoleEntities) =>
        new OptionsClass({
          name: item.name,
          value: String(item.id)
        })
    );
  }
  async submitEditRole() {
    try {
      MainAppController.showLoading();
      MainAppController.closeMessageModal();
      MainAppController.closeErrorMessage();
      await UserController.editRole(
        new EditRoleApiRequest({
          roleId: Number(this.roleDefault.value),
          accountId: UserController.userDetail.id,
          status: this.statusAccount
        })
      );
      this.openSnackbar("Perubahan role berhasil !");
      this.$emit("success");
    } catch (error) {
      MainAppController.showErrorMessage(
        parsingErrorResponse(error, "Role Gagal Diubah !", this.submitEditRole)
      );
    } finally {
      MainAppController.closeLoading();
    }
  }
}
