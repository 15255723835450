
import { Vue, Options } from "vue-class-component";
import { formatInputNumberOnlyV2 } from "@/app/infrastructures/misc/Utils";
import SelectPhoneCountry from "../../shipment/booking/component/select-phone-country.vue";
import removeNewLineWhitespace from "@/app/infrastructures/misc/common-library/RemoveNewLineWhitespace";
import debounce from "lodash/debounce";
import { LocationController } from "@/app/ui/controllers/LocationController";
import { CustomerManagementController } from "@/app/ui/controllers/CustomerManagementController";
import { OptionsClass } from "@/domain/entities/MainApp";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { ValidateInputPhoneNumber } from "@/app/infrastructures/misc/common-library/ValidateInputPhoneNumber";

@Options({
  components: {
    SelectPhoneCountry,
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name === "login") {
      next();
    }
    if (this.hasChanged && !this.openSuccess) {
      this.onOpenClosePage(to);
      if (this.answer) {
        this.answer = false;
        next();
      } else {
        next(false);
      }
    } else {
      this.name = "";
      next();
    }
  },
  watch: {
    "phoneNumber": function() {
      const phoneNumberWithCode = `${this.phoneNumberCode.substring(1)}${this.phoneNumber}`
      const errorPhoneNumber = ValidateInputPhoneNumber(phoneNumberWithCode);
      this.error.successPhoneNumber = errorPhoneNumber.success;
      this.error.phoneNumber = errorPhoneNumber.error;
      this.error.errorMessagePhoneNumber = errorPhoneNumber.message;
    },
  }
})
export default class AddManagementCustomer extends Vue {
  goBack() {
    this.$router.back();
  }

  get title(): string {
    return (this.$route as any)?.meta?.name;
  }

  get isFormValid() {
    return (true);
  }

  onOpenSave() {
    //
  }

 isDisabled = true;

  error = {
    phoneNumber: false,
    phoneNumberCode: false,
    successPhoneNumber: false,
    errorMessagePhoneNumber: false,
    email: false
  };

  // validation name
  name = "";
  validName = true;

  validateName() {
    this.validName = this.name.length !== 0;
  }

  // phone number
  phoneNumber=""
  phoneNumberCode="+62"
  validPhoneNumber = true;

  filterInput(e: any) {
    e.target.value = formatInputNumberOnlyV2(e.target.value);
    this.validatePhoneNumber();
  }

  validatePhoneNumber() {
    this.validPhoneNumber = this.phoneNumber.length !== 0;
  }

  get errorPhoneNumber() {
    return {
      errorPhone: this.error.phoneNumber,
      errorMandatory: this.errorMandatory[1],
      errorPhoneCode: this.error.phoneNumberCode
    };
  }

  setPhoneNumber(val: string) {
    this.phoneNumber = val;
  }

  setPhoneCodeNumber(val: string) {
    this.phoneNumberCode = val;
  }

  setErrorPhoneNumberCode(status: boolean) {
    this.error.phoneNumberCode = status;
  }

  // validation email
  email = "";

  address =""
  validAddress = true
  replaceNewLine(value: string) {
    return removeNewLineWhitespace(value, " ");
  }

  validateAddress() {
    this.validAddress = this.address.length !== 0;
  }

  // error indicator mandatory
  currentFieldIndex = 0;
  setFieldIndex(index: number) {
    if (index > this.currentFieldIndex) {
      this.currentFieldIndex = index;
    }
  }

  get errorMandatory() {
    return [
    !this.isNameValid && this.currentFieldIndex > 0,
    !this.isPhoneNumberValid && this.currentFieldIndex > 1,
    !this.isAddressValid && this.currentFieldIndex > 2,
    ];
  }

  // city
  destinationCity: any = ""
  destinationCityList: Array<any> = [];
  onFocusdestinationCity() {
    if (!this.destinationCityList.length || !this.destinationCity) {
      LocationController.setLoadingDistrict(true);
      this.fetchdestinationCity("");
    }
  }
  fetchdestinationCity = debounce(async (search: string) => {
    const typeAccuracyAllowed = true;
    const response = await LocationController.getDistrictList({
      search: search,
      status: "",
      page: 1,
      limit: typeAccuracyAllowed ? 50 : 10,
      cache: true
    });
    this.destinationCityList = this.filterDistrict(response);
  }, 250);

  get isLoadingdestinationCity() {
    return LocationController.isLoadingDistrict;
  }

  filterDistrict(response: any) {
    return response
      .filter((key: any) => key.status.toLowerCase() === "active")
      .map((e: any) => ({
        id: e.id,
        name: `${e.name}, ${e.cityName}`,
        code: `${e.originZipCode}`
      }));
  }

  onChangeDestinationCity() {
    if (this.destinationCity.code?.includes(",")) {
      const arrayZipcode: string[] = this.destinationCity.code.split(",")
      this.zipCodeList = arrayZipcode.map((e: any) => ({
        code: e,
        name: e
      }))
    } else {
      this.zipCode = this.destinationCity.code ? {code: this.destinationCity.code, name: this.destinationCity.code} : ""
      this.zipCodeList = [{code: this.destinationCity.code, name: this.destinationCity.code}]
    }
  }

  //zipcode
  zipCode: any = ""
  zipCodeList: Array<any> = [];

  fetchZipCode = debounce(async (search: string) => {
    if (!this.destinationCity.code) {
    const response = await LocationController.getZipCodeList({
      search: search,
      page: 1,
      limit: 10,
    });
    this.zipCodeList = this.filterZipCode(response);
    }
  }, 250);

  filterZipCode(response: any) {
    return response
      .zipCodeData
      .map((e: any) => ({
        code: e.originZipCode,
        name: e.originZipCode,
        id: e.id,
        key: `${e.name}, ${e.cityName}`
      }));
  }

  get isLoadingZipCode() {
    return LocationController.isLoadingZipCode;
  }

  onFocusZipCode() {
    if (!this.zipCodeList.length || !this.zipCode) {
      this.fetchZipCode("");
    }
  }

  onChangeZipCode() {
    if (!this.destinationCity.code) {
      this.destinationCity = this.zipCode.name ? {id: this.zipCode.id, name: this.zipCode.key} : ""
    }
  }

  //address type

  addressType = "";
  addressTypeSelect = false;
  addressTypeData = [
    { name: "Pilih tipe alamat ", value: "" },
    { name: "Rumah", value: "rumah" },
    { name: "Kantor", value: "kantor" }
  ];

  onOpenAddressType() {
    this.addressTypeSelect = true;
  }

  onCloseAddressType() {
    this.addressTypeSelect = false;
  }

  onSelectAddressType(name: string, value: string) {
    this.addressType = value;
  }

  get isSubmitLoading(): boolean {
    return CustomerManagementController.isSubmitLoading
  }

  get isErrorForm() {
    return (
      !this.isNameValid ||
      !this.isPhoneNumberValid ||
      !this.isAddressValid ||
      !this.isEmailValid
    );
  }

  get openPhoneNumberIdenticalError() {
    return CustomerManagementController.openModalError;
  }

  get isNameValid() {
    return this.name !== "" && this.name.length > 2 && this.name.length <= 100;
  }

  get isEmailValid() {
    if (this.email === "") {
      return true;
    }
    return !this.error.email;
  }

  get isAddressValid() {
    return this.address !== "" && this.address.length > 2 && this.address.length <= 200;
  }

  get isPhoneNumberValid() {
    return !this.error.phoneNumber && this.phoneNumber !== "";
  }

  handlerPhoneNumberIdenticalClose() {
    return CustomerManagementController.setOpenModalError(false)
  }

  openSnackbar(name: string) {
    MainAppController.setSnackbarMessage(`Penambahan data pelanggan "${name}" berhasil!`);
    MainAppController.setSnackbar(true);
  }

  _onAdd() {
    CustomerManagementController._onAddCustomer({
      name: this.name,
      phone: `${this.phoneNumberCode.substring(1)}${
            this.phoneNumber
          }`,
      email: this.email,
      address: this.address,
      districtId: Number(this.destinationCity.id),
      zipCode: this.zipCode.name,
      addressType: this.addressType.toLocaleLowerCase()
    }).then(()=> {
      const customerName = this.name
      this.$router.push(`/management-customer`).then(() => {
        this.openSnackbar(customerName)
      })
    })
  }

  get errorPhoneSender() {
    return {
      success: this.error.successPhoneNumber,
      errorPhone: this.error.phoneNumber,
      errorMandatory: this.errorMandatory[1],
      errorPhoneCode: this.error.phoneNumberCode,
      message: this.error.errorMessagePhoneNumber
    };
  }

  validateEmail(value: string) {
    this.error.email = !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
  }
};
