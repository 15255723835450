/* eslint-disable @typescript-eslint/camelcase */
import { AddCustomerManagementRequestInterface, EditCustomerManagementRequestInterface } from "../contracts/CustomerManagementResquest";
export class EditCustomerManagementApiRequest implements EditCustomerManagementRequestInterface {
    private name: string;
    private phone: string;
    private address: string;
    private email: string;
    private districtId: number;
    private zipCode: string;
    private addressType: string;
  
    constructor(
      name: string,
      phone: string,
      address: string,
      email: string,
      districtId: number,
      zipCode: string,
      addressType: string
    ) {
      this.name = name;
      this.phone = phone;
      this.address = address;
      this.email = email;
      this.districtId = districtId;
      this.zipCode = zipCode;
      this.addressType = addressType;
    }
  
    public toJSON() {
      const customer_name = this.name;
      const customer_phone = this.phone;
      const customer_address = this.address;
      const customer_email = this.email;
      const customer_district_id = this.districtId;
      const customer_zip_code = this.zipCode;
      const customer_address_type = this.addressType;
  
      return JSON.stringify({
        customer_name,
        customer_phone,
        customer_address,
        customer_email,
        customer_district_id,
        customer_zip_code,
        customer_address_type
      });
    }
  }

export class EditCustomerOnBookingManagementApiRequest implements EditCustomerManagementRequestInterface {
  private name: string;
  private phone: string;
  private address: string;
  private districtId: number;
  private zipCode: string;
  private addressType: string;

  constructor(
    name: string,
    phone: string,
    address: string,
    districtId: number,
    zipCode: string,
    addressType: string,
  ) {
    this.name = name;
    this.phone = phone;
    this.address = address;
    this.districtId = districtId;
    this.zipCode = zipCode;
    this.addressType = addressType
  }

  public toJSON() {
    const customer_name = this.name;
    const customer_phone = this.phone;
    const customer_address = this.address;
    const customer_district_id = this.districtId;
    const customer_zip_code = this.zipCode;
    const customer_address_type = this.addressType;

    return JSON.stringify({
      customer_name,
      customer_phone,
      customer_address,
      customer_district_id,
      customer_zip_code,
      customer_address_type
    });
  }
}

export class AddCustomerManagementApiRequest implements AddCustomerManagementRequestInterface {
    private name: string;
    private phone: string;
    private address: string;
    private email: string;
    private districtId: number;
    private zipCode: string;
    private addressType: string;
  
    constructor(
      name: string,
      phone: string,
      address: string,
      email: string,
      districtId: number,
      zipCode: string,
      addressType: string
    ) {
      this.name = name;
      this.phone = phone;
      this.address = address;
      this.email = email;
      this.districtId = districtId;
      this.zipCode = zipCode;
      this.addressType = addressType;
    }
  
    public toJSON() {
      const customer_name = this.name;
      const customer_phone = this.phone;
      const customer_address = this.address;
      const customer_email = this.email;
      const customer_district_id = this.districtId;
      const customer_zip_code = this.zipCode;
      const customer_address_type = this.addressType;
  
      return JSON.stringify({
        customer_name,
        customer_phone,
        customer_address,
        customer_email,
        customer_district_id,
        customer_zip_code,
        customer_address_type
      });
    }
  }